<template>
  <div>
    <Assistant_teacher_bar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ตรวจสอบผลการประเมิน ตำแหน่งครูผู้ช่วย"
        class="px-5 py-3"
      >
        <v-card>
          <v-row>
            <v-col cols="12" md="12">
              <div class="text-center">
                <v-btn
                  v-if="countCancel >= 1"
                  outlined
                  color="error"
                  class="mr-2"
                  @click="cancelAlert()"
                >
                  <v-icon>mdi-alert</v-icon> แจ้งยกเลิก {{ countCancel }} รายการ
                </v-btn>
                <v-btn
                  v-if="countCC >= 1"
                  @click="ccAlert()"
                  outlined
                  color="warning"
                >
                  <v-icon>mdi-alert</v-icon> อยู่ระหว่างการแก้ไข
                  {{ countCC }} รายการ
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="12">
              <v-data-table
                color="success"
                :loading="loading"
                :headers="headersAlert"
                :items="assistant_teachers"
                :search="search"
                :item-class="row_classes"
              >
                <template v-slot:item.assistant_teacher_comment="{ item }">
                  <div v-if="item.assistant_teacher_comment === 'cancel'">
                    <v-btn
                      @click="cancelAssistant(item.assistant_teacher_id)"
                      rounded
                      color="error"
                      ><v-icon>mdi-alert</v-icon> ยกเลิกไฟล์</v-btn
                    >
                    <div>{{ item.assistant_teacher_comment_detail }}</div>
                  </div>
                </template>

                <template v-slot:item.date_app_now="{ item }">
                  <div>
                    {{
                      item.date_app_now
                        | moment("add", "543 year")
                        | moment("D MMMM YYYY")
                    }}
                  </div>
                </template>

                <template v-slot:item.assistant_teacher_datetime="{ item }">
                  <div>
                    {{
                      item.assistant_teacher_datetime
                        | moment("add", "543 year")
                        | moment("D MMMM YYYY")
                    }}
                  </div>
                </template>

                <template v-slot:item.assistant_teacher_score="{ item }">
                  <v-chip
                    v-if="
                      item.assistant_teacher_score < 50 &&
                        item.assistant_teacher_times <= 2
                    "
                    color="warning"
                    dark
                  >
                    <h2>{{ item.assistant_teacher_score }}</h2>
                  </v-chip>
                  <v-chip
                    v-if="
                      item.assistant_teacher_score < 60 &&
                        item.assistant_teacher_times === '3'
                    "
                    color="warning"
                    dark
                  >
                    <h2>{{ item.assistant_teacher_score }}</h2>
                  </v-chip>
                  <v-chip
                    v-if="
                      item.assistant_teacher_score < 60 &&
                        item.assistant_teacher_times === '4'
                    "
                    color="warning"
                    dark
                  >
                    <h2>{{ item.assistant_teacher_score }}</h2>
                  </v-chip>
                  <v-chip v-else color="green" dark>
                    <h2>{{ item.assistant_teacher_score }}</h2>
                  </v-chip>
                </template>

                <template v-slot:item.assistant_teacher_status="{ item }">
                  <v-chip
                    v-if="item.assistant_teacher_status === 'confirm'"
                    color="green"
                    dark
                  >
                    <v-icon> mdi-cube-send</v-icon> ส่งผลการประเมิน
                  </v-chip>

                  <v-chip
                    v-else
                    color="info"
                    @click="viewFile(item.assistant_teacher_file)"
                  >
                    <v-icon>mdi-printer</v-icon>
                  </v-chip>
                </template>

                <template v-slot:item.assistant_teacher_ipacheck="{ item }">
                  <div>
                    <v-btn
                      v-if="item.assistant_teacher_ipacheck === 'Pass'"
                      fab
                      small
                      color="success"
                      @click="checkAssistant(item.assistant_teacher_id)"
                      ><v-icon>mdi-account-check</v-icon>
                    </v-btn>

                    <v-btn
                      v-else-if="item.assistant_teacher_ipacheck === 'NotPass'"
                      fab
                      small
                      color="red"
                      @click="checkAssistant(item.assistant_teacher_id)"
                      ><v-icon>mdi-account-remove</v-icon>
                    </v-btn>

                    <v-btn
                      v-else
                      fab
                      small
                      color="warning"
                      @click="checkAssistant(item.assistant_teacher_id)"
                      ><v-icon>mdi-account-edit</v-icon>
                    </v-btn>
                  </div>
                </template>

                <v-alert
                  slot="no-results"
                  :value="true"
                  color="error"
                  icon="mdi-alert"
                  >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
                >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="pa-2" outlined color="primary lighten-4">
          <v-row no-gutters>
            <v-col cols="12" md="3" class="pa-1">
              <v-select
                filled
                label="ค้นหาการประเมิน : "
                v-model="select_ass"
                :items="times_ass"
                item-text="text"
                item-value="value"
                @change="assistant_teacherQuerySelect()"
              >
              </v-select>
            </v-col>

            <v-col cols="12" md="3" class="pa-1">
              <v-autocomplete
                filled
                label="ค้นหา วิทยาลัย :"
                v-model="select_college"
                :items="colleges"
                item-text="college_name"
                item-value="college_code"
                @change="assistant_teacherQueryCollegeSelect()"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="3" class="pa-1">
              <v-autocomplete
                filled
                label="ค้นหา รอบบรรจุ :"
                v-model="select_dateapp"
                :items="manage_assistant_teachers"
                item-text="dateApps"
                item-value="dateApps"
                @change="dateAppSelect()"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="3" class="pa-1 text-center">
              <v-btn              
                :href="
                  '#/admin/print_AssismentReportCPDay2/?appoin_day=' +
                    appoin_day +
                    '&appoin_month=' +
                    appoin_month +
                    '&appoin_year=' +
                    appoin_year
                "
                target="_blank"
                color="info"
                v-if="check_param_query > 1"
                ><v-icon>mdi-printer</v-icon> พิมพ์รายงาน</v-btn
              >
            </v-col>


          </v-row>
        </v-card>

        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="manage_assistant_teachersPer"
          :search="search"
          :item-class="row_classes"
          class="elevation-1"
          :single-expand="false"
          show-expand
          item-key="id"
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                filled
                class="mb-2"
              />
            </div>
          </template>
          <template v-slot:item.mt_date_app_now="{ item }">
            <div>
              {{
                item.mt_date_app_now
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
          </template>

          <template v-slot:item.cancelAlert="{ item }">
            <div v-if="item.cancelAlert >= 1">
              <v-icon color="error" size="40">mdi-alert</v-icon>
            </div>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table>
                <tbody>
                  <tr
                    v-for="(child, index) in item.manage_assistant_teacherpf"
                    :key="child.id"
                  >
                    <td>{{ ++index }}</td>
                    <td>
                      {{
                        child.assistant_teacher_datetime
                          | moment("add", "543 year")
                          | moment("D MMMM YYYY")
                      }}
                    </td>

                    <td>
                      <v-chip
                        v-if="
                          child.assistant_teacher_score < 50 &&
                            child.assistant_teacher_times <= 2
                        "
                        color="warning"
                        dark
                      >
                        <h2>{{ child.assistant_teacher_score }}</h2>
                      </v-chip>
                      <v-chip
                        v-if="
                          child.assistant_teacher_score < 60 &&
                            child.assistant_teacher_times === '3'
                        "
                        color="warning"
                        dark
                      >
                        <h2>{{ child.assistant_teacher_score }}</h2>
                      </v-chip>
                      <v-chip
                        v-if="
                          child.assistant_teacher_score < 60 &&
                            child.assistant_teacher_times === '4'
                        "
                        color="warning"
                        dark
                      >
                        <h2>{{ child.assistant_teacher_score }}</h2>
                      </v-chip>
                      <v-chip v-else color="green" dark>
                        <h2>{{ child.assistant_teacher_score }}</h2>
                      </v-chip>
                    </td>
                    <td>
                      <v-chip
                        v-if="child.assistant_teacher_status === 'confirm'"
                        color="green"
                        dark
                      >
                        <v-icon> mdi-cube-send</v-icon> ส่งผลการประเมิน
                      </v-chip>

                      <v-chip
                        v-else
                        color="info"
                        @click="viewFile(child.assistant_teacher_file)"
                      >
                        <v-icon>mdi-printer</v-icon>
                      </v-chip>
                    </td>

                    <td>
                      <div>
                        <v-btn
                          v-if="child.assistant_teacher_ipacheck === 'Pass'"
                          fab
                          small
                          color="success"
                          @click="checkAssistant(child.assistant_teacher_id)"
                          ><v-icon>mdi-account-check</v-icon>
                        </v-btn>

                        <v-btn
                          v-else-if="
                            child.assistant_teacher_ipacheck === 'NotPass'
                          "
                          fab
                          small
                          color="red"
                          @click="checkAssistant(child.assistant_teacher_id)"
                          ><v-icon>mdi-account-remove</v-icon>
                        </v-btn>

                        <v-btn
                          v-else
                          fab
                          small
                          color="warning"
                          @click="checkAssistant(child.assistant_teacher_id)"
                          ><v-icon>mdi-account-edit</v-icon>
                        </v-btn>
                      </div>
                    </td>
                    <td>
                      <div v-if="child.assistant_teacher_comment === 'cancel'">
                        <v-btn
                          @click="cancelAssistant(child.assistant_teacher_id)"
                          rounded
                          color="error"
                          ><v-icon>mdi-alert</v-icon> ยกเลิกไฟล์</v-btn
                        >
                        <div>{{ child.assistant_teacher_comment_detail }}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </td>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addassistant_teacherdialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="addassistant_teacherdialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลรายละเอียดการประเมินครูผู้ช่วยเอก"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addassistant_teacherform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-text-field
                        label="รหัส"
                        v-model="addassistant_teacher.id_assistant_teacher"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="รายละเอียดการประเมินครูผู้ช่วยเอก"
                        v-model="addassistant_teacher.name_assistant_teacher"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-textarea
                        label="รายละเอียด"
                        v-model="addassistant_teacher.detail_assistant_teacher"
                        require
                        :rules="[v => !!v || '']"
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addassistant_teacherdialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addassistant_teacherSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deleteassistant_teacherdialog -->
      <v-layout>
        <v-dialog
          v-model="deleteassistant_teacherdialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลรายละเอียดการประเมินครูผู้ช่วยเอก"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deleteassistant_teacherform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูลรายละเอียดการประเมินครูผู้ช่วยเอก
                          {{ editassistant_teacher.name_assistant_teacher }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deleteassistant_teacherdialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deleteassistant_teacherSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editassistant_teacherdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editassistant_teacherdialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลรายละเอียดการประเมินครูผู้ช่วย"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editassistant_teacherform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-text-field
                        label="รหัส"
                        v-model="editassistant_teacher.id_assistant_teacher"
                        require
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="รายละเอียดการประเมินครูผู้ช่วยเอก"
                        v-model="editassistant_teacher.name_assistant_teacher"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-textarea
                        label="รายละเอียด"
                        v-model="editassistant_teacher.detail_assistant_teacher"
                        require
                        :rules="[v => !!v || '']"
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editassistant_teacherdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editassistant_teacherSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <v-layout row justify-center>
        <v-dialog v-model="assistant_teacher_filedialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecfiles/' + filesAss"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import Assistant_teacher_bar from "../../components/admin/assistant_teacher_bar.vue";
export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      addassistant_teacherdialog: false,
      editassistant_teacherdialog: false,
      deleteassistant_teacherdialog: false,
      select_ass: "",
      times_ass: [
        { text: "แสดงทั้งหมด ", value: "show_all" },
        { text: "ประเมินครั้งที่ 1 ", value: "1" },
        { text: "ประเมินครั้งที่ 2 ", value: "2" },
        { text: "ประเมินครั้งที่ 3 ", value: "3" },
        { text: "ประเมินครั้งที่ 4 ", value: "4" },
        { text: "ส่งผลการประเมิน ", value: "confirm" },
        { text: "ไม่ได้รับการประเมิน ", value: "not_evaluated" }
      ],
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      assistant_teachers: [],
      addassistant_teacher: {},
      editassistant_teacher: {},
      search: "",
      pagination: {},
      headers: [
        { text: "วันที่บรรจุ", align: "left", value: "mt_date_app_now" },

        {
          text: "รหัสสถานศึกษา",
          align: "center",
          value: "mt_college_code"
        },
        { text: "สถานศึกษา", align: "left", value: "college_name" },
        {
          text: "รหัสบัตรประชาชน",
          align: "left",
          value: "mt_id_card"
        },
        { text: "ชื่อ-นามสกุล", align: "left", value: "frist_names" },
        {
          text: "ครั้งที่ประเมินล่าสุด",
          align: "center",
          value: "maxTime"
        },

        {
          text: "แจ้งยกเลิก",
          align: "center",
          value: "cancelAlert"
        }
      ],

      headersAlert: [
        { text: "วันที่บรรจุ", align: "center", value: "date_app_now" },

        {
          text: "รหัสสถานศึกษา",
          align: "left",
          value: "assistant_teacher_college_code"
        },
        { text: "สถานศึกษา", align: "center", value: "college_name" },
        {
          text: "รหัสบัตรประชาชน",
          align: "left",
          value: "assistant_teacher_id_card"
        },
        { text: "ชื่อ-นามสกุล", align: "left", value: "frist_names" },
        {
          text: "ครั้งที่ประเมิน",
          align: "center",
          value: "assistant_teacher_times"
        },
        {
          text: "วันที่ประเมิน",
          align: "center",
          value: "assistant_teacher_datetime"
        },
        { text: "คะแนน", align: "center", value: "assistant_teacher_score" },

        { text: "สถานะ", align: "center", value: "assistant_teacher_status" },
        {
          text: "แจ้งยกเลิก",
          align: "center",
          value: "assistant_teacher_comment"
        },
        {
          text: "ดำเนินการ",
          align: "center",
          value: "assistant_teacher_ipacheck"
        },

        {
          text: "หมายเหตุ",
          align: "center",
          value: "assistant_teacher_ipacomment"
        }
      ],

      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      college: {},
      provinces: [],
      prefectures: [],
      assistant_teacherstatus: [],
      regions: [],
      region_ena: true,
      select_college: "",
      colleges: [],
      manage_assistant_teachers: [],
      manage_assistant_teachersPer: [],
      select_dateapp: "",
      assistant_teachers_edit: [],
      massege_cc: "",
      countCancel: "",
      countCC: "",
      expanded: [],
      singleExpand: false,
      assistant_teacher_filedialog: false,
      filesAss: "",
      check_param_query:''
    };
  },
  async mounted() {
    let result;
    result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });
    this.colleges = result.data;
    await this.manage_assistant_teacherQuery();
    await this.checkappoint();
  },
  methods: {
    async viewFile(assistant_teacher_file) {
      this.filesAss = assistant_teacher_file;
      this.assistant_teacher_filedialog = true;
    },
    async ccAlert() {
      this.loading = true;
      let result = await this.$http
        .post("assistant_teacher.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_comment: "CC"
        })
        .finally(() => (this.loading = false));
      this.assistant_teachers = result.data;
    },
    async cancelAlert() {
      this.loading = true;
      let result = await this.$http
        .post("assistant_teacher.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_comment: "cancel"
        })
        .finally(() => (this.loading = false));
      this.assistant_teachers = result.data;
    },
    async checkappoint() {
      let appoin_day = this.appoin_day || 0;
      let appoin_month = this.appoin_month || 0;
      let appoin_year = this.appoin_year || 0;
      let dates = new Date(appoin_year + "-" + appoin_month + "-" + appoin_day);
      let getTime = dates.getTime();
      this.check_param_query = String(dates.getSeconds).length
      if (getTime > 1) {
        this.loading = true;
        let result = await this.$http
          .post("manage_assistant_teacher.php", {
            ApiKey: this.ApiKey,
            appoin_day: appoin_day,
            appoin_month: appoin_month,
            appoin_year: appoin_year
          })
          .finally(() => (this.loading = false));
        this.manage_assistant_teachersPer = result.data;
        await this.cancelAlert();
      } else {
        await this.cancelAlert();
      }
    },
    async checkAssistant(assistant_teacher_id) {
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: assistant_teacher_id
      });
      this.assistant_teachers_edit = result_assistant.data;
      

      let textAlert =
        this.assistant_teachers_edit.frist_names +
        " ครั้งที่ : " +
        this.assistant_teachers_edit.assistant_teacher_times +
        " เพิ่มเติม >>> ระบุ";
      Swal.fire({
        title: "การตรวจสอบข้อมูล",
        text: textAlert,
        icon: "warning",

        input: "text",
        inputAttributes: {
          autocapitalize: "off"
        },

        showDenyButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ถูกต้อง",
        cancelButtonText: "ยกเลิก",
        denyButtonText: "ไม่ถูกต้อง",
        preConfirm: async login => {
          let response = login;
          this.massege_cc = response;
          return this.massege_cc;
        }
      }).then(async result => {
        if (result.isDenied) {
          

          this.assistant_teachers_edit.ApiKey = this.ApiKey;
          this.assistant_teachers_edit.assistant_teacher_ipacheck = "NotPass";
          this.assistant_teachers_edit.assistant_teacher_ipacomment = this.massege_cc;
          let result = await this.$http.post(
            "assistant_teacher.update.php",
            this.assistant_teachers_edit
          );
          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            await this.checkappoint();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        } else if (result.isConfirmed) {
          

          this.assistant_teachers_edit.ApiKey = this.ApiKey;
          this.assistant_teachers_edit.assistant_teacher_ipacheck = "Pass";
          this.assistant_teachers_edit.assistant_teacher_ipacomment = this.massege_cc;
          let result = await this.$http.post(
            "assistant_teacher.update.php",
            this.assistant_teachers_edit
          );
          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            await this.checkappoint();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async cancelAssistant(assistant_teacher_id) {
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: assistant_teacher_id
      });
      this.assistant_teachers_edit = result_assistant.data;
      

      let textAlert =
        this.assistant_teachers_edit.frist_names +
        " ครั้งที่ : " +
        this.assistant_teachers_edit.assistant_teacher_times;
      Swal.fire({
        title: "ยกเลิกการรายงานคะแนน",
        text: textAlert,
        icon: "warning",
        showDenyButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยันยกเลิก",
        cancelButtonText: "ยกเลิก",
        denyButtonText: `ไม่อนุญาต`
      }).then(async result => {
        if (result.isDenied) {
          
          this.assistant_teachers_edit.ApiKey = this.ApiKey;
          this.assistant_teachers_edit.assistant_teacher_comment = "";
          let result = await this.$http.post(
            "assistant_teacher.update.php",
            this.assistant_teachers_edit
          );
          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            await this.assistant_teacherQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        } else if (result.isConfirmed) {
          this.assistant_teachers_edit.ApiKey = this.ApiKey;
          let resultPic = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" +
              this.assistant_teachers_edit.assistant_teacher_file
          });

          this.assistant_teachers_edit.assistant_teacher_file = "";
          this.assistant_teachers_edit.assistant_teacher_comment = "CC";
          let result = await this.$http.post(
            "assistant_teacher.update.php",
            this.assistant_teachers_edit
          );

          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            await this.assistant_teacherQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async dateAppSelect() {
      let dateTimeApp = this.select_dateapp.split("-");
      let appoin_day = dateTimeApp[0];
      let appoin_month = dateTimeApp[1];
      let appoin_year = dateTimeApp[2];
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        appoin_day: appoin_day,
        appoin_month: appoin_month,
        appoin_year: appoin_year
      });
      this.manage_assistant_teachersPer = result.data;
    },
    async manage_assistant_teacherQuery() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          calendar_admin: "ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async not_evaluated_check() {
      this.loading = true;
      let result = await this.$http
        .post("assistant_teacher.php", {
          ApiKey: this.ApiKey,
          not_evaluated: "Ok"
        })
        .finally(() => (this.loading = false));
      this.assistant_teachers = result.data;
    },
    async assistant_teacherQuerySelect() {
      this.loading = true;
      if (this.select_ass == "confirm") {
        let result = await this.$http
          .post("assistant_teacher.php", {
            ApiKey: this.ApiKey,
            assistant_teacher_status: "confirm"
          })
          .finally(() => (this.loading = false));
        this.assistant_teachers = result.data;
      } else if (this.select_ass == "not_evaluated") {
        let result = await this.$http
          .post("assistant_teacher.php", {
            ApiKey: this.ApiKey,
            not_evaluated: "Ok"
          })
          .finally(() => (this.loading = false));
        this.assistant_teachers = result.data;
      } else if (this.select_ass == "show_all") {
        let result = await this.$http
          .post("assistant_teacher.php", {
            ApiKey: this.ApiKey
          })
          .finally(() => (this.loading = false));
        this.assistant_teachers = result.data;
      } else {
        let result = await this.$http
          .post("assistant_teacher.php", {
            ApiKey: this.ApiKey,
            assistant_teacher_times: this.select_ass
          })
          .finally(() => (this.loading = false));
        this.assistant_teachers = result.data;
      }
    },
    async assistant_teacherQueryCollegeSelect() {
      let appoin_day = this.appoin_day || 0;
      let appoin_month = this.appoin_month || 0;
      let appoin_year = this.appoin_year || 0;
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          appoin_day: appoin_day,
          appoin_month: appoin_month,
          appoin_year: appoin_year,
          mt_college_code: this.select_college
        })
        .finally(() => (this.loading = false));

      this.manage_assistant_teachersPer = result.data;
   
      ;
    },

    async assistant_teacherQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("assistant_teacher.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_lowscore: "ok"
        })
        .finally(() => (this.loading = false));
      this.assistant_teachers = result.data;
      let data = this.assistant_teachers;
      let countCancel = 0;
      let countCC = 0;
      data.forEach(value => {
        if (value.assistant_teacher_comment == "cancel") {
          countCancel += 1;
        }
        if (value.assistant_teacher_comment == "CC") {
          countCC += 1;
        }
      });
      this.countCancel = countCancel;
      this.countCC = countCC;
    },

    async assistant_teacherAdd() {
      this.addassistant_teacher = {};
      this.addassistant_teacherdialog = true;
    },
    async addassistant_teacherSubmit() {
      if (this.$refs.addassistant_teacherform.validate()) {
        this.addassistant_teacher.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "assistant_teacher.insert.php",
          this.addassistant_teacher
        );
        if (result.data.status == true) {
          this.assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.addassistant_teacherdialog = false;
      }
    },
    async assistant_teacherEdit(id_rp) {
      let result = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        id_rp: id_rp
      });
      this.editassistant_teacher = result.data;
      this.editassistant_teacherdialog = true;
    },
    async editassistant_teacherSubmit() {
      if (this.$refs.editassistant_teacherform.validate()) {
        this.editassistant_teacher.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "assistant_teacher.update.php",
          this.editassistant_teacher
        );
        if (result.data.status == true) {
          this.assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editassistant_teacherdialog = false;
      }
    },
    async assistant_teacherDelete(id_rp) {
      let result = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        id_rp: id_rp
      });
      this.editassistant_teacher = result.data;
      this.deleteassistant_teacherdialog = true;
    },
    async deleteassistant_teacherSubmit() {
      if (this.$refs.deleteassistant_teacherform.validate()) {
        this.editassistant_teacher.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "assistant_teacher.delete.php",
          this.editassistant_teacher
        );
        if (result.data.status == true) {
          this.assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการลบผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deleteassistant_teacherdialog = false;
      }
    },
    row_classes(item) {
      if (item.assistant_teacher_comment == "cancel") {
        return "lime";
      }
    }
  },
  computed: {
    appoin_day() {
      return this.$route.query.appoin_day;
    },
    appoin_month() {
      return this.$route.query.appoin_month;
    },
    appoin_year() {
      return this.$route.query.appoin_year;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "deep-purple darken-4";
    }
  },
  components: { Assistant_teacher_bar }
};
</script>
